import { useNavigate } from "react-router-dom";
import { Tooltip } from "flowbite-react";

import { useSelector } from "../../Store";
import { formatTokenAmount } from "../../Utils/formatter";
import Button from "../../Components/button";
import AirdropSection from "./Components/airdrop";
import UnstakeSection from "./Components/unstake";
import StakeSection from "./Components/stake";

export const StakingPage = () => {
  const navigate = useNavigate();

  const userStakedFomo = useSelector((state) => state.session.userStakedFomo);

  const searchNftData = () => {
    navigate("/nft");
  };

  return (
    <>
      <div className=" w-full pt-[110px] lg:flex  text-white bg-[url('../public/images/back1.png')] bg-opacity-0 bg-cover bg-center">
        <img
          src="images/blur-ai.png"
          className="absolute hidden lg:block"
          alt="blur-ai"
        />
        <div className="mx-auto">
          <div className=" bg-[#232325] bg-opacity-10 backdrop-blur-lg lg:min-w-[700px] min-w-[320px] px-[20px] justify-center pt-[40px] pb-1 rounded-lg relative border-[1px] border-gray-600">
            <div className="flex relative"></div>
            <div className="text-[25px] text-green-500 font-bold py-[20px] text-center ">
              AIRDROPS
            </div>

            <AirdropSection />

            <div className=" bg-[#0c0c0c] bg-opacity-60 px-[20px] mb-[5px] border-l-2 border-green-500 pb-3 rounded-b-lg">
              <Button
                onClick={searchNftData}
                className={"rounded-b-lg"}
                content={
                  <div className="flex items-center justify-center ">
                    <p className="mr-2">Check what's in your NFT </p>
                    <img
                      src="images/traced-fomo-left.png "
                      className="w-5"
                      alt="omo-left"
                    />
                    <p className="hidden sm:block">🎁👉</p>
                  </div>
                }
              />
            </div>

            <div className="text-[25px] text-green-500 font-bold py-[20px] text-center ">
              STAKING
            </div>

            <div className="bg-[#0c0c0c] bg-opacity-60 px-[20px] rounded-t-lg my-[5px] border-l-2 border-green-500">
              <div className="flex py-[15px]">
                <div className="w-1/2 text-left">Earn:</div>
                <div className="w-1/2 text-right">FOMO/Airdrops</div>
              </div>
            </div>

            <div className="bg-[#0c0c0c] bg-opacity-60 px-[20px] my-[5px] border-l-2 border-green-500">
              <div className="flex py-[15px]">
                <div className="w-1/2 text-left">Your Staked FOMO:</div>
                <div className="w-1/2 flex items-center justify-end">
                  <Tooltip
                    content={userStakedFomo}
                    className="bg-[#232325] bg-opacity-90 backdrop-blur-lg"
                    arrow={false}
                  >
                    {formatTokenAmount(userStakedFomo)}
                  </Tooltip>
                </div>
              </div>
            </div>

            <UnstakeSection />
            <StakeSection />
            <img
              src="images/traced-fomo-left.png "
              className="absolute left-[0px] top-[-70px] lg:w-[150px] w-[100px] floating"
              alt="omo-left"
            />
            <img
              className="absolute right-[0px] top-[-70px]  lg:w-[150px] w-[100px] floating"
              src="/images/traced-fomo-right.png"
              width={100}
              alt="omo-right"
            />
          </div>
        </div>
      </div>
    </>
  );
};
