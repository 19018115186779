import React from "react";
import { formatTokenAmount } from "../Utils/formatter";

const ModalCard = ({
  isSelected = false,
  img,
  heading,
  value,
  isBalance,
  onClick,
}) => {
  const handleImageError = (e) => {
    e.target.src = "/images/logo.jpg";
  };

  return (
    <div
      onClick={onClick}
      className={`${
        isSelected ? "border-2 border-green-500" : "border border-gray-500"
      } rounded-md text-white min-w-max max-w-max cursor-pointer hover:border-green-500 group bg-[#232325] bg-opacity-60`}
    >
      <div className="mx-3 mt-3 mb-1 flex flex-col items-center justify-center gap-3">
        <img
          src={img ?? "/images/logo.jpg"}
          alt="Card"
          className="rounded-full w-20 h-20 mx-auto bg-cover bg-center bg-no-repeat"
          onError={handleImageError}
        />
        <p className="font-semibold">{heading}</p>
      </div>
      <div
        className={`border-t ${
          isSelected
            ? "border-t-2 border-green-500"
            : "border-t border-gray-500"
        } py-1 group-hover:border-green-500`}
      >
        <p className="" title={value}>
          {isBalance ? formatTokenAmount(+value) : value}
        </p>
      </div>
    </div>
  );
};

export default ModalCard;
