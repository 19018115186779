import { createSlice } from "@reduxjs/toolkit";
import { countUserStakedFOMO } from "../../Utils/helpers";

const initialState = {
  successTx: 0,
  fomoNfts: [],
  tokens: [],
  fomoBalance: "0",
  stakedFomo: 0,
  airdrops: [],
  oldFomoBalance: "0",
  oldFomoNfts: [],
  isMigrateModalShown: false,
  userFomoNfts: [],
};

const session = createSlice({
  name: "session",
  initialState,
  reducers: {
    incrementSuccessTx(state) {
      state.successTx = state.successTx + 1;
    },
    setUserFomoNfts(state, { payload }) {
      state.userFomoNfts = payload;
      state.userStakedFomo = countUserStakedFOMO(payload);
    },
    setUserTokens(state, { payload }) {
      state.userTokens = payload;
    },
    setFomoBalance(state, { payload }) {
      state.fomoBalance = payload;
    },
    setUserStakedFomo(state, { payload }) {
      state.userStakedFomo = payload;
    },
    setLatestAirdrops(state, { payload }) {
      state.latestAirdrops = payload;
    },
    setOldFomoBalance(state, { payload }) {
      state.oldFomoBalance = payload;
    },
    setOldUserFomoNfts(state, { payload }) {
      state.oldFomoNfts = payload;
    },
    setIsMigrateModalShown(state, { payload }) {
      state.isMigrateModalShown = payload;
    },
  },
});

export default session.reducer;

export const {
  incrementSuccessTx,
  setUserFomoNfts,
  setUserTokens,
  setFomoBalance,
  setUserStakedFomo,
  setLatestAirdrops,
  setOldFomoBalance,
  setOldUserFomoNfts,
  setIsMigrateModalShown,
} = session.actions;
