import React, { useState } from "react";
import ModalCard from "./modalCard";

const CardsGroup = ({
  // required
  cardsArray,
  img_key,
  heading_key,
  value_key,
  //   optional
  selectedCards,
  setSelectedCards,
  enableCardSelection = false,
  enableMultipleSelection = false,
  onCardSelect,
  isBalance,
}) => {
  const [selectedCardsIndex, setSelectedCardsIndex] = useState([]);

  const handleCardSelect = (card, index) => {
    if (!enableCardSelection) return;

    let newSelectedCardsIndex = [...selectedCardsIndex];
    const indexPosition = newSelectedCardsIndex.indexOf(index);

    if (enableMultipleSelection) {
      if (indexPosition > -1) {
        newSelectedCardsIndex.splice(indexPosition, 1);
      } else {
        newSelectedCardsIndex.push(index);
      }
    } else {
      newSelectedCardsIndex = indexPosition > -1 ? [] : [index];
    }

    setSelectedCardsIndex(newSelectedCardsIndex);
    const newSelectedCards = newSelectedCardsIndex.map(
      (selectedIndex) => cardsArray[selectedIndex]
    );
    setSelectedCards(newSelectedCards);
    if (onCardSelect) {
      onCardSelect(newSelectedCards);
    }
  };

  return (
    <div className="flex items-start justify-center gap-4 py-4 flex-wrap w-full">
      {cardsArray.length === 0 ? (
        <>
          <p className="text-white text-center w-full">No items</p>
        </>
      ) : (
        cardsArray.map((card, index) => (
          <ModalCard
            key={index}
            img={card[img_key]}
            heading={"ID: " + card[heading_key]}
            value={card[value_key]}
            onClick={() => handleCardSelect(card, index)}
            isSelected={selectedCardsIndex.includes(index)}
            isBalance={isBalance}
          />
        ))
      )}
    </div>
  );
};

export default CardsGroup;
